import "@swan-io/lake/src/assets/fonts/Inter.css";
import "@swan-io/lake/src/assets/main.css";

// Don't load graphiql/graphiql.css as it inline fonts
import "@graphiql/react/dist/style.css";
import "graphiql/src/style.css";

import "./assets/graphiql-explorer.css";
import "./assets/overrides.css";

import { ResizeObserver } from "@juggle/resize-observer";
import "core-js/proposals/array-flat-map";
import "core-js/proposals/change-array-by-copy-stage-4";
import "core-js/proposals/object-from-entries";
import "core-js/proposals/promise-all-settled";
import "core-js/proposals/relative-indexing-method";
import "core-js/proposals/string-replace-all-stage-4";

// overrides shared-business supported languages
import "./utils/i18n";

import { Future, Result } from "@swan-io/boxed";
import { isNullish } from "@swan-io/lake/src/utils/nullish";
import {
  BadStatusError,
  EmptyResponseError,
  NetworkError,
  Request,
  TimeoutError,
  badStatusToError,
  emptyToError,
} from "@swan-io/request";
import {
  GraphQLSchema,
  IntrospectionQuery,
  buildClientSchema,
  getIntrospectionQuery,
} from "graphql";
import { env } from "./utils/env";
import { initSentry } from "./utils/logger";

initSentry();

if (isNullish(window.ResizeObserver)) {
  window.ResizeObserver = ResizeObserver;
}

const fetchSchema = (
  endpoint: string,
): Future<
  Result<GraphQLSchema, NetworkError | TimeoutError | BadStatusError | EmptyResponseError>
> =>
  Request.make({
    url: `${env.DASHBOARD_URL}/api/introspection${endpoint}`,
    type: "json",
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      query: getIntrospectionQuery({ inputValueDeprecation: true }),
    }),
  })
    .mapOkToResult(badStatusToError)
    .mapOkToResult(emptyToError)
    .mapOk(data => data as unknown as { data: IntrospectionQuery })
    .mapOk(({ data }) => buildClientSchema(data));

const optionalSchemas = env.DORA
  ? [
      fetchSchema("/admin"),
      fetchSchema("/sandbox-exposed-internal"),
      fetchSchema("/sandbox-unauthenticated"),
    ]
  : [];

Future.allFromDict({
  app: Future.fromPromise(import("./App")),
  schemas: Future.all([fetchSchema("/sandbox-partner"), fetchSchema("/sandbox-partner-admin")]).map(
    Result.all,
  ),
  optionalSchemas: Future.all(optionalSchemas).map(Result.all),
})
  .map(Result.allFromDict)
  .tapOk(
    ({
      app,
      schemas: [apiSchema, testApiSchema],
      optionalSchemas: [adminSchema, sandboxExposedInternalSchema, sandboxUnauthenticatedSchema],
    }) => {
      app.run({
        apiSchema,
        testApiSchema,
        adminSchema,
        sandboxExposedInternalSchema,
        sandboxUnauthenticatedSchema,
      });
    },
  )
  .tapError(error => console.error(error));

console.log(
  `%c👋 Hey, looks like you're curious about how Swan works!
%c👀 Swan is looking for many curious people.

%c➡️ Feel free to check out https://www.welcometothejungle.com/fr/companies/swan/jobs, or send a message to join-us@swan.io`,
  "font-size: 1.125em; font-weight: bold;",
  "font-size: 1.125em;",
  "font-size: 1.125em;",
);
